import React, { useEffect, useRef } from 'react';
import { Grid, GridRow } from '@naf/grid';
import { CardSize, CardNavigation } from '@naf/cards';
import { Text, TextVariant } from '@naf/text';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CampaignBannerVariant } from '@naf/banner';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { Transition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { SectionHeader } from '@naf/section-header';
import { breakpoints, spacing } from '@naf/theme';
import { AdvancedImage } from '@cloudinary/react';
import { UserInteraction, UserInteractionVariant } from '@naf/user-interaction';
import { useCloudinary } from '../../../hooks/Cloudinary/useCloudinary';
import { Layout, MainContent } from '../../../components/layout/Layout';
import { DEFAULT_IMG_URL } from '../../../lib/constants';
import S from './styles';
import { useSiteStructure } from '../../../hooks/SiteStructure/useSiteStructure';
import useSelector from '../../../redux/typedHooks';
import { actions } from '../../../redux/modules/centers';
import { NavCardLink } from '../../../components/styled-link/StyledLink';
import { CenterProductsType } from '../../../../../types/centerProductsType';
import {
  CentersPageData,
  OtherInformationType,
  OtherInformationTypeExternal,
  OtherInformationTypeInternal,
} from '../../../../../types/centerData';
import { CampaignBlock } from '../../../components/block-content/components/types/components/CampaignBlock';
import { isInternalLink } from '../../../lib/isInternalLink';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { AnimationWrapper } from '../../../components/skeleton-loaders/AnimationWrapper';
import { ServiceProductLoader } from '../../../components/skeleton-loaders/ServiceProductLoader';
import { useResponsiveImage } from '../../../hooks/useResponsiveImage';
import CenterSelect from './CenterSelect';
import ErrorBoundary from '../../../components/error-component/ErrorBoundary';
import { useWindowLocation } from '../../../hooks/Window/useWindowLocation';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';
import { NafCenterAppType } from '../../../../../types/CategoryAppType';
import { NotFound } from '../../../loadable-elements/NotFound';

interface NafCenterServicesProps {
  slug: string;
  data: CentersPageData;
  appData?: NafCenterAppType;
}

const ProductCard = ({
  product,
  path,
  isAvailable,
}: {
  product: CenterProductsType;
  path: string;
  isAvailable: boolean;
}) => (
  <>
    {isAvailable && product.isAvailable && product.link && (
      <S.ShortCutCol s="12" m="6" l="4" xl="4">
        <NavCardLink to={`${path}/${product.link.data.slug}`}>
          <CardNavigation title={product.title} size={CardSize.Medium} isInternalLink height="100%">
            {product.isCampaign && <S.StyledLabel variant="moss" text="Kampanje" />}
            {product.isForMembersOnly && <S.StyledLabel variant="signature" text="Medlemseksklusivt" />}
            {product.ingress}
          </CardNavigation>
        </NavCardLink>
      </S.ShortCutCol>
    )}
    {!isAvailable && !product.isAvailable && (
      <S.ShortCutCol s="12" m="6" l="4" xl="4">
        <CardNavigation disabled title={product.title} size={CardSize.Medium} height="100%">
          {product.ingress}
        </CardNavigation>
      </S.ShortCutCol>
    )}
  </>
);

export const NafCenterServices = ({ slug, data, appData }: NafCenterServicesProps) => {
  const dispatch = useDispatch();
  const [centerId, setCenterId] = useSessionStorage('centerId', undefined);
  const { properties } = appData;
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const { centerList, centerProducts } = useSelector((state) => state.centers);

  const placeholderGridRef = useRef<HTMLDivElement | null>(null);
  const gridRef = useRef<HTMLDivElement | null>(null);

  // Check if is mappedCategory (for SEO purposes)
  const mappedCategory = useSiteStructure(slug);

  const cld = useCloudinary();

  const categoryImage =
    (mappedCategory?.data?.image?.publicId &&
      cld
        ?.image(mappedCategory.data.image.publicId)
        .resize(fill().width(1200).height(630))
        .quality('auto:best')
        .format('auto')
        .toURL()) ||
    undefined;

  const windowPath = useWindowLocation();

  const placeholderList = [...Array(12).keys()];

  // Get available centers from API
  useEffect(() => {
    if (!centerList.data && !centerList.isUpdating && !centerList.error) {
      dispatch(actions.getCenters.request(undefined, undefined));
    }
  }, [centerList, dispatch]);

  // Get available center products from API
  useEffect(() => {
    dispatch(actions.getCenterProducts.request(centerId as number));
  }, [centerId, dispatch]);

  const mainImage = useResponsiveImage({
    imageId: data.image?.publicId,
    initialDimensions: { width: 400, height: 448 },
  });

  const selectedCenter = centerId ? centerList?.data?.find((element) => element.centerId === centerId) : undefined;
  const hasNonAvailableProducts = centerProducts.data?.some((product) => !product.isAvailable);

  if (data) {
    return (
      <Layout
        title={mappedCategory?.data?.seoConfig?.title || 'Naf Senter'}
        description={
          mappedCategory?.data?.ingress || mappedCategory?.data?.seoConfig?.introduction || 'Naf Senter tjenester'
        }
        imgUrl={categoryImage || DEFAULT_IMG_URL}
        url={windowPath}
        gtmArgs={{
          page_type: 'Naf Senter',
          pageCategory: 'Naf Senter',
          contentId: '',
        }}
        seoTitle={mappedCategory?.data?.seoConfig?.title || 'Naf Senter'}
        seoDescription={mappedCategory?.data?.seoConfig?.introduction || 'Naf Senter tjenester'}
        internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
      >
        <StyledSectionHeader
          title={mappedCategory?.data?.name}
          imageOrVideo={
            mainImage && (
              <StyledAdvancedImage cldImg={mainImage} alt={data.image?.altText || 'NAF Senter illustrasjon'} />
            )
          }
          ingress={data.ingress || properties?.body[0]?.children[0]?.text}
        />
        <StyledMainContent>
          <ErrorBoundary>
            <Grid>
              <CenterSelect
                centerId={centerId}
                handleSelect={(option) => setCenterId(option.value ? parseInt(option.value, 10) : false)}
                selectedCenter={selectedCenter}
                slug={slug}
              />
              <TransitionGrid>
                {(!centerProducts.data || centerProducts.isUpdating) && !centerProducts.error ? (
                  <Transition
                    nodeRef={placeholderGridRef}
                    in={!centerProducts.data || centerProducts.isUpdating}
                    timeout={{ enter: 250, exit: 0 }}
                    unmountOnExit
                    mountOnEnter
                  >
                    {(state) => (
                      <AnimationWrapper ref={placeholderGridRef} $state={state}>
                        <S.MainRow>
                          {placeholderList.map((i) => (
                            <S.ShortCutCol s="12" m="6" l="4" xl="4" key={i}>
                              <ServiceProductLoader key={i} />
                            </S.ShortCutCol>
                          ))}
                        </S.MainRow>
                      </AnimationWrapper>
                    )}
                  </Transition>
                ) : (
                  <Transition
                    nodeRef={gridRef}
                    key="data"
                    in={centerProducts.data && !centerProducts.isUpdating}
                    timeout={250}
                    unmountOnExit
                    mountOnEnter
                  >
                    {(state) => (
                      <AnimationWrapper ref={gridRef} $state={state}>
                        <S.MainRow>
                          {centerProducts.data?.map((product) => (
                            <ProductCard
                              isAvailable
                              key={`${product.title}-${product.link.data.slug}`}
                              product={product}
                              path={slug}
                            />
                          ))}
                        </S.MainRow>
                      </AnimationWrapper>
                    )}
                  </Transition>
                )}
              </TransitionGrid>
              {centerProducts.error && (
                <UserInteraction
                  maxWidth={300}
                  title="Oops, noe gikk galt!"
                  variant={UserInteractionVariant.Error}
                  button={{
                    onClick: () => {
                      dispatch(actions.getCenterProducts.request(centerId as number));
                    },
                    children: 'Prøv igjen',
                    type: 'button',
                  }}
                />
              )}
              <S.LinkCol s="12" m="12" l="12" xl="12">
                <Text>Ønsker du å endre en allerede bestilt time kan du gjøre dette inne på </Text>
                <Link to="/mitt-naf">Mitt NAF</Link>
                <Text>.</Text>
              </S.LinkCol>
              {centerId && centerProducts.data && !centerProducts.isUpdating && hasNonAvailableProducts && (
                <S.MainRow>
                  <S.NotAvailableCol s="12" m="12" l="12" xl="12">
                    <Text variant={TextVariant.Header2}>
                      Tilbys ikke på {selectedCenter?.title || 'dette senteret'}
                    </Text>
                    <Text>
                      Dette sentret tilbyr ikke følgende tjenester. Prøv et annet NAF-senter i nærheten for disse
                      tjenstene.
                    </Text>
                  </S.NotAvailableCol>
                  {centerProducts.data.map((product: CenterProductsType) => (
                    <ProductCard
                      isAvailable={false}
                      key={`${product.title}-${product.link.data.slug}`}
                      product={product}
                      path={slug}
                    />
                  ))}
                </S.MainRow>
              )}
              {properties?.otherInformation && properties.otherInformation.length > 0 && (
                <S.SectionCol s="12" m="12" l="12" xl="12">
                  <Text variant={TextVariant.Header2}>Annen nyttig informasjon</Text>
                  <GridRow>
                    {properties.otherInformation.map((card: OtherInformationType) => {
                      if (card.type === 'internalLink') {
                        return (
                          <S.ShortCutCol s="12" m="6" l="4" xl="4" key={card.key}>
                            <NavCardLink to={`${(card.data as OtherInformationTypeInternal).slug}`}>
                              <CardNavigation
                                isInternalLink
                                title={(card.data as OtherInformationTypeInternal).name || ''}
                                size={CardSize.Medium}
                                height="100%"
                              />
                            </NavCardLink>
                          </S.ShortCutCol>
                        );
                      }
                      const { isInternal, relativeLink } = isInternalLink(
                        baseUrl,
                        (card.data as OtherInformationTypeExternal).href,
                      );
                      if (isInternal) {
                        return (
                          <S.ShortCutCol s="12" m="6" l="4" xl="4" key={card.key}>
                            <NavCardLink to={relativeLink}>
                              <CardNavigation
                                isInternalLink
                                title={(card.data as OtherInformationTypeExternal).title || ''}
                                size={CardSize.Medium}
                                height="100%"
                              />
                            </NavCardLink>
                          </S.ShortCutCol>
                        );
                      }
                      return (
                        <S.ShortCutCol s="12" m="6" l="4" xl="4" key={card.key}>
                          <S.ExternalLink href={`${(card.data as OtherInformationTypeExternal).href}`}>
                            <CardNavigation
                              isInternalLink
                              title={(card.data as OtherInformationTypeExternal).title || ''}
                              size={CardSize.Medium}
                              height="100%"
                            />
                          </S.ExternalLink>
                        </S.ShortCutCol>
                      );
                    })}
                  </GridRow>
                </S.SectionCol>
              )}
              {properties?.campaignBlock && (
                <S.SectionCol s="12" m="12" l="12" xl="12">
                  <CampaignBlock value={{ ...properties.campaignBlock, variant: CampaignBannerVariant.Medium }} />
                </S.SectionCol>
              )}
            </Grid>
          </ErrorBoundary>
        </StyledMainContent>
      </Layout>
    );
  }
  return <NotFound />;
};

const TransitionGrid = styled(TransitionGroup)`
  grid-column: 1 / -1;
`;

const StyledMainContent = styled(MainContent)`
  margin-bottom: ${spacing.space120};
`;

const StyledAdvancedImage = styled(AdvancedImage)`
  @media (max-width: ${breakpoints.s}) {
    display: none;
  }
`;

const StyledSectionHeader = styled(SectionHeader)`
  h1 {
    padding-top: ${spacing.space160};

    @media (max-width: ${breakpoints.l}) {
      padding-top: ${spacing.space120};
    }
    @media (max-width: ${breakpoints.m}) {
      padding-top: 95px;
    }
  }
`;
